<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="50" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems" dense
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." single-select>
					<template v-slot:top>
						<div class="d-flex flex-wrap pt-1 pb-1" style="gap:16px" ref="tabletopbar">
							<v-btn-toggle mandatory dense v-model="filter" @change="loadData">
								<v-btn>进行中</v-btn>
								<v-btn>已完成</v-btn>
								<v-btn>全部</v-btn>
							</v-btn-toggle>
							<v-text-field placeholder="姓名/身份证号/手机号" v-model.trim="psearch" clearable dense hide-details style="flex: 0 1 auto" class="ml-4"
								append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
							<v-checkbox v-model="showArchive" label="显示已关闭订单" dense hide-details class="ml-8" @change="loadData"/>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-on="on" @click="printSticker(item)" class="mr-2">mdi-barcode</v-icon>
							</template>
							打印条码
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-on="on" @click="editNote(item)" class="mr-1" :color="item.color">edit_note</v-icon>
							</template>
							患者备注
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-on="on" @click="editItem(item)" class="mr-1">mdi-square-edit-outline</v-icon>
							</template>
							修改基础信息
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-show="canSweep" v-on="on" @click.stop="showArchiveDialog(item)">delete_outline</v-icon>
							</template>
							关闭订单
						</v-tooltip>
					</template>
					<template v-slot:item.idcard="{ item }">
						{{getMaskedIdcard(item)}}
					</template>
					<template v-slot:item.age="{ item }">
						{{getAge(item)}}
					</template>
					<template v-slot:item.opts="{ item }">
						{{getOptsStr(item)}}
					</template>
					<template v-slot:item.steps="{ item }">
						<v-stepper flat width="400" :value="0" style="background-color:rgba(255,255,255,0)">
							<v-stepper-header style="height:48px">
								<template v-for="(s, i) in item.steps">
									<v-stepper-step :key="`${i}-step`" :step="i+1" class="py-0 pl-0" :complete="s.state !== 0" 
										:complete-icon="s.icon" :color="s.color">
										<v-tooltip bottom>
											<template v-slot:activator="{ on }">
												<span v-on="on">{{ s.text }}</span>
											</template>
											{{ s.hint }}
										</v-tooltip>
									</v-stepper-step>
									<v-divider v-if="i !== item.steps.length - 1" :key="i"></v-divider>
								</template>
							</v-stepper-header>
						</v-stepper>
					</template>
				</v-data-table>
			</pane>
			<!--
			<pane min-size="20" size="50" v-if="(selected.length > 0)">
				<div style="height:100%;" class="overflow-y-auto">
	            </div>
			</pane>
			-->
		</splitpanes>
		<v-dialog v-model="dialog" width="360">
			<v-card>
				<v-card-title>关闭订单</v-card-title>
				<v-card-text>
					<v-form :disabled="!!currentItem.hide">
						<v-radio-group dense hide-details label="关闭订单原因" v-model="reason" class="mt-1">
							<v-radio v-for="n in reasons" :key="n" :label="n" :value="n"/>
						</v-radio-group>
						<v-text-field clearable label="备注" v-model="comment" class="pt-2 px-1"/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :disabled="!!currentItem.hide||!reason" :loading="loading" @click.stop="sweep">确定关闭</v-btn>
					<v-btn color="primary" :disabled="!currentItem.hide" :loading="loading" @click.stop="unsweep">恢复订单</v-btn>
					<v-btn color="primary" outlined @click.stop="dialog=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import {printSticker0} from '../zebra'
	import {buildSteps, setPatientColor} from '../workset3'
	import NewPatient from '../components/NewPatient3.vue'
	import PatientNote from '../components/PatientNote.vue'

    export default {
        data() {
            return {
                authed: false,
				canSweep: false,
                headers: [
                    {text:'', value:'actions', width:144, sortable: false, class:'pr-0', cellClass:'pr-0'},
					{text:'姓名', value:'pii.username', width:120},
					{text:'性别', value:'pii.gender', width:80},
					{text:'年龄', value:'age', width:80},
					{text:'进度', value:'steps', width:400, sortable: false, cellClass:"pr-0"},
					{text:'电话', value:'pii.phone', width:100},
					{text:'证件号', value:'idcard', width:120},
					{text:'套餐内容', value:'opts', width:180, sortable: false},
					{text:'代理人', value:'consultantName', width:100},
					{text:'订单号', value:'_id', width:80},
//					{text:'病案号', value:'pid', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {},
				totalItems: 0,
				tableheight: undefined,
				filter: 0,
				psearch: '',
				showArchive: false,
				dialog: false,
				currentItem: {},
				reasons: [
					"信息填写错误",
					"评估未通过",
					"客户放弃",
					"用于测试",
					"其他原因"
				],
				reason: null,
				comment: '',
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
			}
		},
		created() {
			this.formatTime = formatTime;
		},
        mounted() {
			this.authed = this.$hasPrivilege(['镜检进度','代理人']);
			if (!this.authed) return;
			this.canSweep = this.$hasPrivilege(['关闭订单','代理人']);
		},
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 39;
				setTimeout(() => {
    				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.$hasPrivilege('镜检进度')) {
					//admin也有此权限
				} else {
					const u = this.$getCurrentUser();
					filter.push({consultantUsername: u.username});
				}
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				if (!this.showArchive) {
					filter.push({'hide' : _.neq(true)});
				}
				filter.push({category:_.neq('clinic')});
				if (this.filter === 0) {
					filter.push({completed:_.neq(true)});
				} else if (this.filter === 1) {
					filter.push({completed:_.eq(true)});
				}
				let f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					res.data.forEach(x => {
						x.steps = buildSteps(x);
						x.steps.forEach(y => {
							y.icon = ['','schedule','$complete','close','redo','event'][y.state];
							y.color = ['grey','accent','success','error','warning','info'][y.state];
						});
					});
					await setPatientColor(db, res);
					this.items = res.data;
					if (this.selected.length > 0) {
						const s = this.items.find(x => x._id === this.selected[0]._id);
						this.selected = s ? [s] : [];
					}
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				if (!item.opts) return '';
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			async editItem(item) {
				const onSave = async(pii) => {
					this.loading = true;
					try {
						await this.$callCloudFunc({
							funcname:'updatePii',
							data: {
								id: item._id,
								pii,
							}
						});
						return true;
					} catch (err) {
						console.error(err);
						return false;
					} finally {
						this.loading = false;
					}
				}
				const result = await this.$dialog.showAndWait(NewPatient, {width:600, pii:item.pii, onSave, editMode:true});
				if (result === true) {
					await this.loadData();
					this.$dialog.message.success('修改完成');
				} else if (result === false) {
					this.$dialog.message.error('修改失败');
				}
			},
			showArchiveDialog(item) {
				this.currentItem = item;
				this.reason = null;
				this.dialog = true;
			},
			async sweep() {
				 if (this.currentItem.completed && this.reason !== '用于测试') {
					this.$dialog.message.error('已完成订单不可归档');
					return;
				 }
				const id = this.currentItem._id;
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'archiveOrder',
						data: {
							id,
							reason: this.reason,
							comment: this.comment
						}
					});
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async unsweep() {
				const id = this.currentItem._id;
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'unarchiveOrder',
						data: {
							id,
						}
					});
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async printSticker(item) {
                item.age = calculateAge(item.pii.dob);
				const res = await this.$dialog.prompt({
					title: '打印条码',
					text: '数量',
					rules: [(v) => parseInt(v) !== NaN || '请输入有效数字'],
					value: '1',
					textField: {
						// Any addtional props/attrs that will be binded to v-text-field component
						type: 'number',
						max: '20',
						min: '1'
					},
					width: 200,
					actions: {false:'取消', true:{text:'打印',color:'primary'}}
				});
				const n = parseInt(res);
				if (!n || n < 0 || n > 20) return;
				printSticker0(item, n, err => {
					console.error(err);
					this.$dialog.error({title:'打印失败', text:err});
				});
			},
			async editNote(item) {
				await this.$dialog.showAndWait(PatientNote, {width:600, pid:item.pid});
				await this.fetchData();
			},
		},
        components: { Splitpanes, Pane }
    }
</script>
